import React from 'react';

import Modal from '@common/components/Modal';

export default function NewInstanceModal({
  show,
  onClose,
  onSubmit,
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: ({ mode, domains }: { mode: string; domains: string[] }) => void;
}) {
  return (
    <Modal
      isShowing={show}
      onClose={onClose}
      onSubmit={() =>
        onSubmit({
          mode: 'test',
          domains: ['http://localhost'],
        })
      }
      title={`Create new "Injury IQ" instance`}
    >
      <div className="mt-4">
        After creation, the below settings for your new Injury IQ plugin
        instance cannot be changed.
        <div className="mt-4 mb-6 grid grid-cols-1 gap-4">
          <div>
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700"
            >
              Plugin
            </label>
            <select
              id="type"
              name="type"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option>Injury IQ</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="mode"
              className="block text-sm font-medium text-gray-700"
            >
              Mode
            </label>
            <select
              id="mode"
              name="mode"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              defaultValue="Canada"
            >
              <option>Test</option>
              <option>Production</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="domains"
              className="block text-sm font-medium text-gray-700"
            >
              Authorized Domain(s) (comma-separated)
            </label>
            <input
              id="domains"
              name="domains"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              type="text"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
