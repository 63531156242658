import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';

import {
  AnnotationIcon, ClipboardListIcon, ClockIcon, PhotographIcon
} from '@heroicons/react/solid';

import { KnockNotificationFeed } from '../../services/knock';

export default function Inbox() {
  return (
    <div className="bg-white rounded-md shadow flex flex-col items-stretch overflow-hidden">
      <KnockNotificationFeed
        inlineStyle={{ height: 300 }}
        displayMode="inline"
      />
    </div>
  );
}

// const ICONS = {
//   'claim.media_added': { icon: PhotographIcon, color: 'text-green-400' },
//   'claim.assigned': { icon: ClipboardListIcon, color: 'text-blue-500' },
//   'claim.mentioned': { icon: AnnotationIcon, color: 'text-indigo-500' },
//   'claim.idled': { icon: ClockIcon, color: 'text-gray-400' },
// };

// const notifications = [
//   {
//     type: 'claim.media_added',
//     title: 'New images added to [[C-908217]]',
//     subtitle: 'John Doe added license plate and closeup damage photos',
//     relative_date: '43 minutes ago',
//   },
//   {
//     type: 'claim.assigned',
//     title: 'Claim [[C-4693412]] assigned to you',
//     subtitle: '',
//     relative_date: '2 hours ago',
//   },
//   {
//     type: 'claim.mentioned',
//     title: 'New mention in [[C-4693412]]',
//     subtitle: 'Adjuster Marge Example mentioned you',
//     relative_date: '4 hours ago',
//   },
//   {
//     type: 'claim.idled',
//     title: '[[C-4693412]] (assigned to you) is idle',
//     // subtitle: 'Adjuster Marge Example mentioned you',
//     relative_date: '7 hours ago',
//   },
// ];

// export default function Inbox() {
//   return (
//     <div className="">
//       <div className="flex flex-col">
//         <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
//           <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
//             <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
//               <table className="min-w-full divide-y divide-gray-200">
//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {notifications.map(notification => {
//                     const iconData =
//                       notification.type in ICONS
//                         ? ICONS[notification.type as keyof typeof ICONS]
//                         : null;

//                     const title = reactStringReplace(
//                       notification.title,
//                       /\[\[(.*?)\]\]/g,
//                       (match, i) => (
//                         <span
//                           key={i}
//                           className="group-hover:text-blue-800 text-blue-600 font-semibold whitespace-no-wrap"
//                         >
//                           {match}
//                         </span>
//                       ),
//                     );

//                     return (
//                       <tr
//                         key={notification.title}
//                         className="group cursor-pointer"
//                       >
//                         <td className="px-6 py-4 whitespace-nowrap text-sm inline-flex items-center">
//                           <div className="mr-4">
//                             {iconData ? (
//                               <iconData.icon
//                                 className={classNames(
//                                   'h-5 w-5',
//                                   iconData.color,
//                                 )}
//                               />
//                             ) : null}
//                           </div>
//                           <div>
//                             <div className="font-medium text-gray-900">
//                               {title}
//                             </div>
//                             <div className="text-gray-500">
//                               {notification.subtitle}
//                             </div>
//                           </div>
//                         </td>
//                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                           {notification.relative_date}
//                         </td>
//                         <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
//                           <a
//                             href="#"
//                             className="text-blue-600 hover:text-blue-900 text-lg"
//                           >
//                             ×
//                           </a>
//                         </td>
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
