import { gql } from '@apollo/client';
import { IntakeDataType } from '@common/types/Claim';

export type IntakeData = {
  claim: IntakeDataType;
};

export const INTAKE_FRAGMENT = `
  fragment IntakeFragment on Claim {
    id
    intakeIdentifier
    intakeSummary
    intakeSummaryShort
    intakeAssignedTo
    intakeFilingParty
    intakeFilingDate
    intakeStatus
    intakeComments {
      id
      createdAt
      adminUserId
      message
    }
  }
`;

export const GET_INTAKE_DETAILS = gql`
  query claimIntake($id: ID!) {
    claim(id: $id) {
      ...IntakeFragment
    }
  }

  ${INTAKE_FRAGMENT}
`;

export const UPDATE_INTAKE_STATUS = gql`
  mutation updateClaimIntakeStatus(
    $id: ID!
    $assignedTo: String
    $status: ClaimIntakeStatus!
  ) {
    updateClaimIntakeStatus(id: $id, assignedTo: $assignedTo, status: $status) {
      ...IntakeFragment
    }
  }

  ${INTAKE_FRAGMENT}
`;

export const ADD_INTAKE_COMMENT = gql`
  mutation addClaimIntakeComment($id: ID!, $comment: String!) {
    addClaimIntakeComment(id: $id, comment: $comment) {
      ...IntakeFragment
    }
  }

  ${INTAKE_FRAGMENT}
`;
