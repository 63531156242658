import React, { useState } from 'react';

import Layout from '../shared/Layout';
import AvailablePlugins from './AvailablePlugins';
import Instances from './Instances';
import NewInstanceModal from './NewInstanceModal';
import Stats from './Stats';
import { Instance as InstanceType } from './types';

const PLUGINS = [
  {
    key: 'injury_iq',
    title: 'Injury IQ',
    graphic:
      'https://uploads-ssl.webflow.com/5f2090d3e03e3d72250fe265/609be3e4da7ac9540f03f8bd_injury-iq-01.svg',
    description:
      'Act quicker and better on costly losses by digitally triaging injury claims at FNOL. Injury IQ is a radical rethink of the status quo—by ingesting in-depth and highly structured injury data, we open exposures and route claims to the appropriate handler. Even better: we preserve a time-stamped audit trail of symptom reports to counter future litigation.',
    available: true,
  },
  {
    key: 'collision_iq',
    title: 'Collision IQ',
    graphic:
      'https://uploads-ssl.webflow.com/5f2090d3e03e3d72250fe265/6061310aed0c900df08485a5_collision%20iq-p-1080.png',
    description:
      'Know more than the basics of a collision—act on deep comprehension of the accident scene. With Assured Collision IQ, understand in live 3D animation how the accident unfolded. Leverage satellite-driven terrain data, compute relative geometry and velocities, and even estimate the damage of the impact. All as a no-code drop in plugin loved by policyholders and adjusters alike.',
    available: false,
  },
  {
    key: 'prophecy',
    title: 'Prophecy',
    graphic:
      'https://uploads-ssl.webflow.com/5f2090d3e03e3d72250fe265/607ef000fde9acab6d335dc6_fraud_prophecy_crystal_ball-01-01.svg',
    description:
      'See fraud before it even occurs. Assured Prophecy observes policyholder behavior on your web portal or native mobile app to identify malicious behavior, enabling adaptive downstream handling of select claims. Prophecy Mobile SDK even includes telematics features like GPS tracking and accelerometer-driven accident detection to trigger automated FNOL sequences.',
    available: false,
  },
  {
    key: '3d_damage',
    title: '3D Damage Assessment',
    graphic:
      'https://uploads-ssl.webflow.com/5f2090d3e03e3d72250fe265/605e8df17a71c70508f06101_Group%20133.svg',
    description:
      'A complete understanding of where and how a vehicle is damaged is critical to speedily approve trustworthy claims, and reject potentially inflated invoices. Using our patent-pending 3D Damage engine, any carrier can capture high-fidelity damage information while providing a delightful customer experience.',
    available: false,
  },
  {
    key: 'protect_iq',
    title: 'Protect IQ',
    graphic:
      'https://uploads-ssl.webflow.com/5f2090d3e03e3d72250fe265/605e8df2e13d9f1ed20e0e61_Group%20141.svg',
    description:
      'First Notice isn’t just about gatherieng preliminary information about a claim. Phone agents instruct callers to fullfill their obligation to prevent further loss, but instructions are often haphazard and nonstandard—and virtually nonexistent in virtual channels. Protect IQ is the answer. We ingest high-level claim details, and serve a dynamic list of step-by-step instructions for claimants to complete within the FNOL process. Reduce losses and provide a value added service to claimants, all in a single product.',
    available: false,
  },
  {
    key: 'omnidirectional_chatbot_text',
    title: 'Omnidirectional Chatbot + Text',
    graphic:
      'https://uploads-ssl.webflow.com/5f2090d3e03e3d72250fe265/605e8df29a7d3d5677f57934_Group%20143.svg',
    description:
      'Human conversation has long been a part of the claims process. Assured’s chatbot plugin provides an “in a box” solution to answer common questions with pre-trained Natural Language Processing algorithms. And if AI can’t answer the question, our omnidirectional texting platform allows claimants, agents, adjusters and service providers to all chat in one place.',
    available: false,
  },
  {
    key: 'e_signature',
    title: 'E-Signature',
    graphic:
      'https://uploads-ssl.webflow.com/5f2090d3e03e3d72250fe265/605e8df107aae9a54bf364d6_Group%20140.svg',
    description:
      'Start collecting electronic signatures in minutes with Assured’s E-Signature solution. Compatible with both desktop and moblie web + native applications, Assured gathers legally binding signatures for downstream compliance and legal uses.',
    available: false,
  },
];

const Heading: React.FC<{}> = ({ children }) => {
  return (
    <div className="mt-4 h-6 relative">
      <div className="absolute top-3 w-full h-px bg-gray-300"></div>
      <h3 className="absolute left-0 top-0 h-6 text-lg leading-6 font-medium text-gray-900 pr-2 bg-gray-50">
        {children}
      </h3>
    </div>
  );
};

export default function ClaimDashboard() {
  const [instances, setInstances] = useState<InstanceType[]>([
    {
      id: 'aaa',
      type: 'injury_iq',
      active: false,
      activity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10],
      api_key: 'pk_ertcw4etx34e5etfgwresg43543',
      mode: 'production',
      authorized_domains: ['https://assured-demo.net'],
    },
  ]);

  const [showNewInstanceModal, setShowNewInstanceModal] = useState(false);
  return (
    <>
      <Layout navigation={[{ current: true, name: 'Plugins' }]}>
        <main className="mt-8 max-w-7xl mx-auto px-8 flex flex-col pb-12">
          {/* <Heading>Recent activity</Heading>
          <Stats /> */}
          <NewInstanceModal
            show={showNewInstanceModal}
            onClose={() => setShowNewInstanceModal(false)}
            onSubmit={instance => {
              setShowNewInstanceModal(false);
              setTimeout(() => {
                setInstances(i => [
                  ...i,
                  {
                    id: 'NEW_' + Math.random().toString(),
                    type: 'injury_iq',
                    mode: 'test',
                    authorized_domains: instance.domains,
                    active: true,
                    activity: [],
                    api_key: `pk_skeryfsehl4iry34oefsdwl34w34te`,
                  },
                ]);
              }, 250);
            }}
          />
          <Heading>Active instances</Heading>
          <Instances instances={instances} />
          <Heading>Available plugins</Heading>
          <AvailablePlugins
            plugins={PLUGINS}
            onCreate={() => setShowNewInstanceModal(true)}
          />
        </main>
      </Layout>
    </>
  );
}
