import classNames from 'classnames';
import React from 'react';

import Button from '@common/components/Button';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';

interface AvailablePluginsProps {
  plugins: {
    key: string;
    title: string;
    graphic: string;
    description: string;
    available: boolean;
  }[];
  onCreate: () => void;
}

const AvailablePlugins: React.FC<AvailablePluginsProps> = ({
  plugins,
  onCreate,
}) => {
  return (
    <div className="my-5 grid gap-5 grid-cols-2">
      {plugins.map(plugin => {
        return (
          <div className="bg-white relative rounded-md shadow px-4 py-5 sm:p-6 flex items-center">
            <img src={plugin.graphic} className="w-28 mr-8" />
            <div>
              <h2 className="font-medium text-xl mb-1">{plugin.title}</h2>
              <div className="text-gray-500 text-xs">{plugin.description}</div>
              <div className="flex items-start gap-4 mt-4">
                {plugin.available ? (
                  <>
                    <Button buttonColor="indigo" onClick={() => onCreate()}>
                      Create new instance
                    </Button>
                    {plugin.available ? (
                      <Button buttonColor="white" onClick={() => {}}>
                        View documentation
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <>
                    <Button buttonColor="white" onClick={() => {}}>
                      Not currently enabled &ndash; contact Assured to enable
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AvailablePlugins;
