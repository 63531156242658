import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import React, { useEffect, useState } from 'react';
import { Sparklines, SparklinesBars } from 'react-sparklines';

import Modal from '@common/components/Modal';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';

import { Instance } from './types';

function InstanceCodeModal({
  instance,
  show,
  onClose,
}: {
  instance: Instance;
  show: boolean;
  onClose: () => void;
}) {
  return (
    <Modal isShowing={show} onClose={onClose} title="Installation Code">
      <div className="mt-4">
        To install this Injury IQ plugin instance, copy and paste the following
        code into your web application.
        <pre className="whitespace-pre text-xs overflow-x-scroll bg-gray-50 border border-gray-200 p-4 rounded my-5">
          {`
<script
  id="assured-plugin-loader"
  src="https://plugins.assuredclaims.net/loader.js"
  data-key="${instance.api_key}"
></script>
<script>
  var target = document.getElementById('AssuredPluginDestination');
  Assured.load(target, {
    type: '${instance.type}',
    claim_identifier: '123-456-7890',
    onSubmit: function () {
      markAsReadyForNextStep();
    }
  });
</script>
`.trim()}
        </pre>
        That's it! Claims will begin flowing into your system.
      </div>
    </Modal>
  );
}

function InstanceRow({ instance }: { instance: Instance }) {
  const [showApiKey, setShowApiKey] = useState(false);
  const [showCode, setShowCode] = useState(false);

  useEffect(() => {
    if (instance.id.startsWith('NEW_')) {
      setShowCode(true);
    }
  }, []);

  return (
    <>
      <InstanceCodeModal
        instance={instance}
        show={showCode}
        onClose={() => setShowCode(false)}
      />
      <tr className="bg-white border-b border-gray-200">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {instance.type === 'injury_iq' ? 'Injury IQ' : 'None'}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex items-center">
          <span className="mr-2">
            Active {instance.activity.length === 0 ? '(new)' : ''}
          </span>
          <span className="text-blue-500">
            <Sparklines data={instance.activity} svgWidth={70} svgHeight={10}>
              <SparklinesBars style={{ fill: 'currentColor' }} />
            </Sparklines>
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span className="font-mono bg-gray-200 font-medium text-xs">
            {showApiKey
              ? instance.api_key
              : `${instance.api_key.substring(0, 8)}...`}
          </span>
          <button
            className="font-medium text-xs border border-gray-300 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-500 focus:outline-none focus:shadow-outline-blue rounded px-1 ml-2"
            onClick={() => setShowApiKey(!showApiKey)}
          >
            {showApiKey ? 'Hide' : 'Show'}
          </button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {instance.authorized_domains.join(', ')}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {capitalize(instance.mode)}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <button
            className="text-indigo-600 hover:text-indigo-900 font-medium focus:outline-none mr-4"
            onClick={() => setShowCode(true)}
          >
            View Code
          </button>
          <button className="text-indigo-600 hover:text-indigo-900 font-medium focus:outline-none mr-4">
            Pause
          </button>
          <button className="text-indigo-600 hover:text-indigo-900 font-medium focus:outline-none">
            Delete
          </button>
        </td>
      </tr>
    </>
  );
}

export default function Credentials({ instances }: { instances: Instance[] }) {
  return (
    <div className="my-5">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Activity (Last 24h)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      API Key
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Authorized Domain
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Mode
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {instances.map(instance => (
                    <InstanceRow key={instance.id} instance={instance} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
