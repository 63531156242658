import classNames from 'classnames';
import React from 'react';

import config from '../../config';
import { AdminClaim, AdminClaimFilter, AdminClaimQueryResponse } from './types';

interface TableTabsProps {
  selectedTab: AdminClaimFilter;
  onSelectTab: (t: AdminClaimFilter) => void;
  countsData?: { [k in AdminClaimFilter]: number };
}

const TableTabs: React.FC<TableTabsProps> = ({
  selectedTab,
  onSelectTab,
  countsData,
}) => {
  const tabs = [
    { key: AdminClaimFilter.draft, name: 'Draft' },
    { key: AdminClaimFilter.submitted, name: 'Submitted' },
  ]
    .concat(
      config.intakeDisableAssignment
        ? []
        : [
            { key: AdminClaimFilter.assigned_self, name: 'Assigned (me)' },
            {
              key: AdminClaimFilter.assigned_others,
              name: 'Assigned (others)',
            },
          ],
    )
    .concat([{ key: AdminClaimFilter.processed, name: 'Processed' }])
    .map(t => ({
      ...t,
      current: t.key === selectedTab,
      count: countsData?.[t.key],
    }));

  return (
    <div className="block">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map(tab => (
            <button
              key={tab.key}
              className={classNames(
                tab.current
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => onSelectTab(tab.key)}
            >
              {tab.name}
              <span
                className={classNames(
                  tab.current
                    ? 'bg-indigo-100 text-indigo-600'
                    : 'bg-gray-200 text-gray-900',
                  'hidden ml-3 py-0.5 w-8 text-center rounded-full text-xs font-medium md:inline-block',
                )}
              >
                {typeof tab.count === 'number' ? `${tab.count}` : '…'}
              </span>
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default TableTabs;
