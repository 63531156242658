import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { useQueryStringParsed } from '@frontend/hooks';

import config from '../../config';
import { useUsers } from '../../hooks/useUsers';
import { GET_CLAIM_COUNTS, GET_CLAIMS_SINGLE } from './queries';
import TableTabs from './TableTabs';
import { AdminClaimFilter, AdminClaimQueryResponse } from './types';

const PAGE_SIZE = 20;

const SESSION_STORAGE_KEY = 'assured$$ClaimDashboardTableTab';

export default function Table() {
  const location = useLocation();
  const history = useHistory();
  const qs = useQueryStringParsed(location);

  const { users } = useUsers();

  // Note: Remove URL-based interaction for now since
  // const selectedTab = (qs.get('filter') ||
  //   AdminClaimFilter.assigned_self) as AdminClaimFilter;
  const [selectedTab, setSelectedTab] = useState<AdminClaimFilter>(
    window.sessionStorage[SESSION_STORAGE_KEY] ||
      (config.intakeDisableAssignment
        ? AdminClaimFilter.submitted
        : AdminClaimFilter.assigned_self),
  );

  useEffect(() => {
    window.sessionStorage[SESSION_STORAGE_KEY] = selectedTab;
  }, [selectedTab]);

  const { data: countsData } = useQuery(GET_CLAIM_COUNTS, {
    pollInterval: 1000 * 10, // every 10s
    fetchPolicy: 'cache-and-network',
  });
  const { data, loading, fetchMore } = useQuery<AdminClaimQueryResponse>(
    GET_CLAIMS_SINGLE,
    {
      variables: { limit: PAGE_SIZE, filter: selectedTab },
      pollInterval: 1000 * 10, // every 10s
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  );
  const claims = data?.adminClaims || [];

  return (
    <div>
      <TableTabs
        countsData={countsData}
        selectedTab={selectedTab}
        // onSelectTab={t => history.replace(`${location.pathname}?filter=${t}`)}
        onSelectTab={t => setSelectedTab(t)}
      />

      <div className="mt-6 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Claim Identifier
                    </th>
                    <th
                      scope="col"
                      className="w-3/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Classification
                    </th>
                    <th
                      scope="col"
                      className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Filing Party
                    </th>
                    <th
                      scope="col"
                      className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Filing Date
                    </th>
                    {!config.intakeDisableAssignment ? (
                      <th
                        scope="col"
                        className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Assignee
                      </th>
                    ) : null}
                    <th scope="col" className="w-1/12 relative px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data ? (
                    claims.length === 0 ? (
                      <tr>
                        <td
                          className="px-6 py-8 text-center text-sm font-medium text-gray-500"
                          colSpan={100}
                        >
                          No claims found
                        </td>
                      </tr>
                    ) : (
                      claims.map(claim => (
                        <tr
                          key={claim.id}
                          className="group cursor-pointer hover:bg-gray-50 relative"
                        >
                          <td className="w-2/12 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            <Link
                              to={`/claims/${claim.id}`}
                              className="absolute inset-0"
                            />
                            {claim.intakeIdentifier}
                          </td>
                          <td className="w-3/12 px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                            {claim.intakeSummary}
                          </td>
                          <td className="w-2/12 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {claim.intakeFilingParty}
                          </td>
                          <td className="w-2/12 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {moment(claim.intakeFilingDate).format('lll')}
                          </td>
                          {!config.intakeDisableAssignment ? (
                            <td className="w-2/12 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {claim.intakeAssignedTo
                                ? users.find(
                                    u => u.user_id === claim.intakeAssignedTo,
                                  )?.name
                                : 'Not yet assigned'}
                            </td>
                          ) : null}
                          <td className="w-1/12 px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <span className="ml-4 text-indigo-600 hover:text-indigo-800 group-hover:text-indigo-800">
                              View
                            </span>
                          </td>
                        </tr>
                      ))
                    )
                  ) : (
                    <tr>
                      <td
                        className="px-6 py-8 text-center text-sm font-medium text-gray-500"
                        colSpan={100}
                      >
                        Loading claims...
                      </td>
                    </tr>
                  )}
                  {data &&
                  countsData &&
                  claims.length < countsData?.[selectedTab] ? (
                    <tr>
                      <td
                        className="px-6 py-4 text-center text-sm font-medium text-gray-500"
                        colSpan={100}
                      >
                        <button
                          className={classNames(
                            'text-indigo-600 font-medium focus:outline-none',
                            loading
                              ? 'opacity-50 cursor-not-allowed'
                              : 'hover:text-indigo-800',
                          )}
                          onClick={() => {
                            fetchMore({
                              query: GET_CLAIMS_SINGLE,
                              variables: {
                                filter: selectedTab,
                                limit: PAGE_SIZE,
                                cursor: claims[claims.length - 1].id,
                              },
                            });
                          }}
                        >
                          Load more
                        </button>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
