import { gql } from '@apollo/client';

const ADMIN_CLAIM_FRAGMENT = gql`
  fragment AdminClaimFragment on Claim {
    id
    intakeIdentifier
    intakeSummary
    intakeFilingParty
    intakeFilingDate
    intakeAssignedTo
  }
`;

export const GET_CLAIM_COUNTS = gql`
  query getAdminClaimsCount {
    draft: adminClaimsCount(filter: draft)
    submitted: adminClaimsCount(filter: submitted)
    assigned_self: adminClaimsCount(filter: assigned_self)
    assigned_others: adminClaimsCount(filter: assigned_others)
    processed: adminClaimsCount(filter: processed)
  }
`;

export const GET_CLAIMS_SINGLE = gql`
  query getAdminClaimsSingle(
    $filter: AdminIntakeClaimsFilter!
    $limit: Int
    $cursor: ID
  ) {
    adminClaims(filter: $filter, limit: $limit, cursor: $cursor) {
      ...AdminClaimFragment
    }
  }

  ${ADMIN_CLAIM_FRAGMENT}
`;
