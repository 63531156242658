export interface AdminClaim {
  id: string;
  intakeIdentifier: string;
  intakeSummary: string;
  intakeFilingParty: string;
  intakeFilingDate: string;
  intakeAssignedTo: string;
}

export enum AdminClaimFilter {
  draft = 'draft',
  submitted = 'submitted',
  assigned_self = 'assigned_self',
  assigned_others = 'assigned_others',
  processed = 'processed',
}

export type AdminClaimQueryResponse = {
  adminClaims: AdminClaim[];
};
