import React from 'react';
import classNames from 'classnames';
import { VehicleDamagePickerStepComponent } from '@common/types/ClaimWorkflow';

import DamagePicker from '../VehicleDamagePicker';
import {
  StepComponentSharedProps,
  StepComponentFC,
} from './types/stepComponentTypes';

type VehicleDamagePickerProps = StepComponentSharedProps<
  VehicleDamagePickerStepComponent,
  string | null
>;

const VehicleDamagePicker: StepComponentFC<VehicleDamagePickerProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
}) => {
  return (
    <div className={classNames('mt-4', className)}>
      <DamagePicker
        initialValue={step_component.existing_value}
        onChange={damageMesh => {
          updateValue(step_component.field, damageMesh);
        }}
      />
    </div>
  );
};

VehicleDamagePicker.stepConfig = {
  manualSubmit: true,
};

export default VehicleDamagePicker;
