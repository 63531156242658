import React, { useEffect, useState } from 'react';

import NumberSegmentedInput from '@common/components/NumberSegmentedInput';

enum FormState {
  EMAIL_ENTRY = 1,
  CODE_ENTRY = 2,
}

const VALID_EMAIL = /@assured\.claims$/;
const VALID_CODES = [
  '177569',
  '975456',
  '170472',
  '515181',
  '635771',
  '677950',
];

const AuthenticationForm: React.FC<{
  onSuccess: () => void;
}> = ({ onSuccess }) => {
  const [formState, setFormState] = useState<FormState>(FormState.EMAIL_ENTRY);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [enteredCode, setEnteredCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onEmailSubmit = () => {
    setError('');
    setIsLoading(true);
    setTimeout(() => {
      if (VALID_EMAIL.test(email)) {
        setFormState(FormState.CODE_ENTRY);
      } else {
        setError('Unauthorized email address. Please try again.');
        setIsLoading(false);
      }
    }, 200 + Math.random() * 500);
  };
  const onCodeSubmit = () => {
    setError('');
    setIsLoading(true);
    setTimeout(() => {
      if (VALID_CODES.includes(enteredCode)) {
        onSuccess();
      } else {
        setError('Invalid pin. Please try again.');
        setEnteredCode('');
        setIsLoading(false);
      }
    }, 300 + Math.random() * 500);
  };
  const restart = () => {
    setEmail('');
    setEnteredCode('');
    setError('');
    setFormState(FormState.EMAIL_ENTRY);
  };

  return (
    <main className="pt-12 sm:pt-24 text-center mx-auto max-w-md">
      <div className="text-center font-bold text-gray-500 mb-4">
        {formState === FormState.EMAIL_ENTRY
          ? `Please log in to continue.`
          : `Please confirm the pin that was just sent to your email address to complete the sign-in process.`}
      </div>
      {formState === FormState.EMAIL_ENTRY ? (
        <form
          onSubmit={e => {
            e.preventDefault();
            onEmailSubmit();
          }}
        >
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <input
                className="form-input w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:block sm:text-md sm:leading-7"
                placeholder="Email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={isLoading}
                autoFocus
              />
            </div>
            <button
              type="submit"
              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-md leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
              disabled={isLoading}
            >
              <span className="">{isLoading ? 'Loading...' : 'Continue'}</span>
            </button>
          </div>
        </form>
      ) : formState === FormState.CODE_ENTRY ? (
        <div className="mt-4">
          <NumberSegmentedInput
            digits={6}
            chunkSize={3}
            value={enteredCode}
            onChange={code => setEnteredCode(code)}
            onFinish={onCodeSubmit}
            autoFocus
          />
        </div>
      ) : null}
      {error ? (
        <div className="mt-4 text-red-500 font-medium">
          {error}{' '}
          {formState === FormState.CODE_ENTRY ? (
            <button
              className="underline font-bold focus:outline-none hover:text-red-400"
              onClick={() => restart()}
            >
              Restart
            </button>
          ) : null}
        </div>
      ) : null}
    </main>
  );
};

export default AuthenticationForm;
