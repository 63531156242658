import React from 'react';

const CONTAINER_STYLE = {
  position: 'relative',
  display: 'inline-block',
  marginRight: -10,
} as const;

const fontSize = 20;
const width = 36;
const height = 48;
const padding = 6;
const separatorWidth = 20;

const INPUT_STYLE = ({
  selectedIndex,
  hideInput,
  chunkSize,
}: {
  selectedIndex: number;
  hideInput?: boolean;
  chunkSize: number;
}) =>
  ({
    position: 'absolute',
    border: 0,
    padding: 0,
    fontSize: fontSize,
    textAlign: 'center',
    backgroundColor: 'transparent',
    outline: 'none',
    width: width,
    lineHeight: `${height}px`,
    top: 0,
    bottom: 0,
    left: `${
      selectedIndex * (width + padding) +
      (chunkSize ? Math.floor(selectedIndex / chunkSize) * separatorWidth : 0)
    }px`,
    opacity: hideInput ? 0 : 1,
  } as const);

const FIELD_STYLE = {
  width: width,
  marginRight: padding,
  height: height,
  lineHeight: `${height}px`,
  verticalAlign: 'top',
  fontSize: fontSize,
  border: 0,
  boxShadow: '0 0 2px #96999C',
  borderRadius: 3,
  textAlign: 'center',
  display: 'inline-block',
  color: '#000',
  backgroundColor: '#fff',
} as const;

const SEPARATOR_STYLE = {
  display: 'inline-block',
  width: separatorWidth - padding,
  marginRight: padding,
  lineHeight: `${height}px`,
  fontSize: fontSize,
} as const;

const FOCUSED_FIELD_STYLE = {
  boxShadow: '0 0 0 3px rgb(164 202 254 / 45%)',
  border: '1px solid #a4cafe',
} as const;

const FINISHED_FIELD_STYLE = {
  color: '#ccc',
} as const;

interface NumberSegmentedInputProps {
  autoFocus: boolean;
  value: string;
  digits: number;
  chunkSize: number;
  onFinish: () => void;
  onChange: (v: string) => void;
}
interface NumberSegmentedInputState {
  focused: boolean;
  hasFinished: boolean;
}

class NumberSegmentedInput extends React.Component<
  NumberSegmentedInputProps,
  NumberSegmentedInputState
> {
  input?: HTMLInputElement | null;

  constructor(props: NumberSegmentedInputProps) {
    super(props);
    this.state = {
      focused: false,
      hasFinished: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.autoFocus && this.input) {
      this.input.focus();
    }
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps({
    value,
    digits,
    onFinish,
  }: NumberSegmentedInputProps) {
    if (!this.state.hasFinished && onFinish && value.length === digits) {
      this.setState({ hasFinished: true }, () => this.props.onFinish());
    } else if (value.length < digits && this.state.hasFinished) {
      this.setState({ hasFinished: false });
    }
  }

  onChange(v: string) {
    v = v.replace(/[^0-9]*/g, '');
    this.props.onChange(v);
  }

  render() {
    const { focused, hasFinished } = this.state,
      { digits, chunkSize, value, onFinish } = this.props;

    const selectedIndex = Math.min(value.length, digits),
      hideInput = selectedIndex === digits;

    return (
      <div style={CONTAINER_STYLE} onClick={() => this.input?.focus()}>
        <input
          style={INPUT_STYLE({ selectedIndex, hideInput, chunkSize })}
          type="text"
          autoComplete="one-time-code"
          ref={r => {
            this.input = r;
          }}
          value=""
          onChange={e =>
            !hasFinished &&
            value.length < digits &&
            this.onChange(value + e.target.value.toUpperCase())
          }
          onKeyUp={e =>
            !hasFinished &&
            e.key === 'Backspace' &&
            this.onChange(value.substring(0, value.length - 1))
          }
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
        />
        {new Array(digits).fill(0).map((c, i) => {
          return (
            <span>
              <div
                key={i}
                style={Object.assign(
                  {},
                  FIELD_STYLE,
                  focused && selectedIndex === i && FOCUSED_FIELD_STYLE,
                  hasFinished && FINISHED_FIELD_STYLE,
                )}
              >
                {value[i]}
              </div>
              {i + 1 < digits && (i + 1) % chunkSize === 0 ? (
                <span style={SEPARATOR_STYLE}>-</span>
              ) : null}
            </span>
          );
        })}
      </div>
    );
  }
}

export default NumberSegmentedInput;
