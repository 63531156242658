import React from 'react';

import Layout from '../shared/Layout';
import Inbox from './Inbox';
import Table from './Table';

const Heading: React.FC<{}> = ({ children }) => {
  return (
    <div className="h-6 relative">
      <div className="absolute top-3 w-full h-px bg-gray-300"></div>
      <h3 className="absolute left-0 top-0 h-6 text-lg leading-6 font-medium text-gray-900 pr-2 bg-gray-50">
        {children}
      </h3>
    </div>
  );
};

export default function ClaimDashboard() {
  return (
    <>
      <Layout navigation={[{ current: true, name: 'Intake' }]}>
        <main className="mt-12 max-w-7xl mx-auto px-8 flex flex-col pb-12">
          {/* <Heading>
            {config.tenant}'s last 30 days
          </Heading>
          <Stats /> */}
          <div className="">
            <Heading>Your inbox</Heading>
            <div className="mt-4">
              <Inbox />
            </div>
          </div>
          <div className="mt-8">
            <Heading>Claims</Heading>
            <div className="mt-2">
              <Table />
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}
