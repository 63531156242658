import { ClaimType } from '@common/types/Claim';

function shouldShowVehicleTab(claim: ClaimType) {
  const { claimVehicle, hitAndRunParties, involvedParties } = claim;

  if (claimVehicle?.licensePlate && claimVehicle?.licensePlateState) {
    return true;
  }
  if (hitAndRunParties.length) {
    for (const party of hitAndRunParties) {
      if (
        party?.manualVehicleLicensePlate &&
        party?.manualVehicleLicensePlateState
      ) {
        return true;
      }
    }
  }

  if (involvedParties.length) {
    for (const party of involvedParties) {
      if (
        party?.manualVehicleLicensePlate &&
        party?.manualVehicleLicensePlateState
      ) {
        return true;
      }
    }
  }
  return false;
}

export default shouldShowVehicleTab;
