import classNames from 'classnames';
import React, {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Canvas,
  extend,
  ReactThreeFiber,
  useFrame,
  useThree,
} from 'react-three-fiber';
import { Group, Object3D } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

//@ts-ignore
import suvObj from './new_car.obj';
//@ts-ignore
import teslaObj from './tesla_car.obj';

const MAIN_DAMAGE_COLOR = '#FF69B4';
const PRIOR_DAMAGE_COLOR = '#06B6D4';

extend({ OrbitControls });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<
        OrbitControls,
        typeof OrbitControls
      >;
    }
  }
}

interface SharedCarProps {
  canvasData: MutableRefObject<Record<string, any>>;
  enableDrawing: boolean;
  restoreAt: number | null;
}

interface CarChunkProps extends SharedCarProps {
  object: Object3D;
  canvasDataKey: string;
}

let _isWebglAvailable: boolean | undefined = undefined;
function isWebGLAvailable() {
  if (typeof _isWebglAvailable !== 'undefined') {
    return _isWebglAvailable;
  }
  try {
    const canvas = document.createElement('canvas');
    const isSupported = !!(
      window.WebGLRenderingContext &&
      (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
    );
    _isWebglAvailable = isSupported;
  } catch (e) {
    _isWebglAvailable = false;
  }
  return _isWebglAvailable;
}

const CarChunk: React.FC<CarChunkProps> = ({
  object,
  enableDrawing,
  canvasData,
  canvasDataKey,
  restoreAt,
}) => {
  const canvasRef = useRef(document.createElement('canvas'));
  const textureRef = useRef<{ needsUpdate: boolean }>({ needsUpdate: false });

  useLayoutEffect(() => {
    const canvas = canvasRef.current;

    canvas.width = 1024;
    canvas.height = 1024;

    const context = canvas.getContext('2d');
    if (context) {
      context.rect(0, 0, canvas.width, canvas.height);
      context.fillStyle = '#ffffff';
      context.fill();
    }
  }, []);

  useEffect(() => {
    if (restoreAt) {
      const context = canvasRef.current.getContext('2d');
      if (canvasData.current[canvasDataKey]) {
        const img = new Image();
        img.onload = function () {
          context && context.drawImage(img, 0, 0);
          textureRef.current.needsUpdate = true;
        };
        img.src = canvasData.current[canvasDataKey];
      } else if (context) {
        context.rect(0, 0, canvasRef.current.width, canvasRef.current.height);
        context.fillStyle = '#ffffff';
        context.fill();
        textureRef.current.needsUpdate = true;
      }
    }
  }, [restoreAt]);

  return (
    <mesh
      onPointerMove={
        enableDrawing
          ? ({ uv, buttons, stopPropagation, ...rest }) => {
              if (uv) {
                const canvas = canvasRef.current;

                const x = uv.x * canvas.width;
                const y = (1 - uv.y) * canvas.height;

                const context = canvas.getContext('2d');
                if (context) {
                  context.beginPath();
                  context.arc(x - 2, y - 2, 20, 0, 2 * Math.PI);
                  context.fillStyle = '#FF69B4';
                  context.fill();
                  delete canvasData.current.unsupported; // if drawing, it is supported
                  canvasData.current.dirty = true;
                  canvasData.current[canvasDataKey] = canvas.toDataURL();
                  textureRef.current.needsUpdate = true;
                }
              }
            }
          : undefined
      }
      // onPointerLeave={() => {
      //   if (enableDrawing)
      //     canvasData.current[canvasDataKey] = canvasRef.current.toDataURL();
      // }}
      onPointerUp={() => {
        if (enableDrawing)
          canvasData.current[canvasDataKey] = canvasRef.current.toDataURL();
      }}
      {...object}
    >
      <meshStandardMaterial attach="material" metalness={0} roughness={1}>
        <canvasTexture
          ref={textureRef}
          attach="map"
          image={canvasRef.current}
        />
      </meshStandardMaterial>
    </mesh>
  );
};

interface CarProps extends SharedCarProps {
  [key: string]: any;
}

const Car: React.FC<CarProps> = ({
  canvasData,
  enableDrawing,
  restoreAt,
  isTesla,
  ...props
}) => {
  const [obj, set] = useState<Group>();
  useMemo(
    () =>
      new OBJLoader().load(isTesla ? teslaObj : suvObj, object => {
        set(object);
      }),
    [],
  );

  if (!obj) {
    return null;
  }

  return (
    <group {...props} rotation={[0, 0, 0]} scale={[1, 1, 1]} dispose={null}>
      {obj.children.map(c => {
        if (c.name === 'Logo') return null;
        return (
          <CarChunk
            key={c.uuid}
            object={c}
            enableDrawing={enableDrawing}
            canvasData={canvasData}
            canvasDataKey={c.name}
            restoreAt={restoreAt}
          ></CarChunk>
        );
      })}
    </group>
  );
};

const CameraControls = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef<OrbitControls>();
  useFrame(state => controls.current?.update());
  return (
    <>
      <orbitControls
        screenSpacePanning={false}
        minDistance={3}
        maxDistance={5}
        rotateSpeed={0.7}
        enableKeys={false}
        ref={controls}
        args={[camera, domElement]}
      />
    </>
  );
};

interface DamagePickerProps {
  initialValue?: string | null;
  overlayValue?: string | null;
  value?: string | null;
  onChange: (damageMesh: string | null) => void;
  viewOnly?: boolean;
  isTesla?: boolean;
  isSubtle?: boolean;
}

const DamagePicker: React.FC<DamagePickerProps> = ({
  initialValue,
  value,
  overlayValue,
  onChange,
  viewOnly,
  isTesla: _isTesla,
  isSubtle,
}) => {
  const [isSupported] = useState(isWebGLAvailable());
  const [mode, setMode] = useState<string>('move');
  const [isPointerDown, setIsPointerDown] = useState<boolean>(false);
  const [stack, setStack] = useState<string[]>(['{}']);

  const isTesla = _isTesla || document.body.classList.contains('tenant-tesla');

  let initialCanvasData = {} as any;
  if (initialValue) {
    try {
      initialCanvasData = JSON.parse(initialValue);
    } catch (e) {}
  }

  const canvasData = useRef<Record<string, string>>(initialCanvasData);
  const [restoreAt, setRestoreAt] = useState<number | null>(
    initialValue && !overlayValue ? Date.now() : null,
  );

  // Controlled component with only a base value.
  useEffect(() => {
    if (value && !overlayValue) {
      canvasData.current = JSON.parse(value);
      setRestoreAt(Date.now());
    }
  }, [value, overlayValue]);

  // Controlled component with a base value AND an overlay value.
  useEffect(() => {
    async function injectOverlay({
      value,
      overlayValue,
    }: {
      value: string;
      overlayValue: string;
    }) {
      const SIZE = 1024;

      const damage = JSON.parse(value),
        priorDamage = JSON.parse(overlayValue),
        unifiedDamage: any = {};

      const loadImage = ({
        img,
        src,
      }: {
        img: HTMLImageElement;
        src: string;
      }) => {
        return new Promise<void>((resolve, reject) => {
          img.onload = () => resolve();
          img.onerror = () => reject();
          img.src = src;
        });
      };

      const drawToCanvas = async ({
        dataUri,
        ctx,
        mode,
      }: {
        dataUri: string;
        ctx: CanvasRenderingContext2D;
        mode: 'main' | 'prior';
      }) => {
        const img = new Image();
        await loadImage({ img, src: dataUri });

        const tempCanvas = document.createElement('canvas');
        const tempContext = tempCanvas.getContext('2d');
        if (tempContext) {
          tempContext.canvas.width = SIZE;
          tempContext.canvas.height = SIZE;
          tempContext.drawImage(img, 0, 0);

          // Replace all white pixels with transparent
          let imgd = tempContext.getImageData(0, 0, SIZE, SIZE),
            pix = imgd.data,
            newColor = { r: 0, g: 0, b: 0, a: 0 };
          for (let i = 0, n = pix.length; i < n; i += 4) {
            let r = pix[i],
              g = pix[i + 1],
              b = pix[i + 2];
            if (r == 255 && g == 255 && b == 255) {
              pix[i] = newColor.r;
              pix[i + 1] = newColor.g;
              pix[i + 2] = newColor.b;
              pix[i + 3] = newColor.a;
            }
          }
          tempContext.putImageData(imgd, 0, 0);

          tempContext.fillStyle =
            mode === 'main' ? MAIN_DAMAGE_COLOR : PRIOR_DAMAGE_COLOR;
          tempContext.globalCompositeOperation = 'source-in';
          tempContext.fillRect(0, 0, img.width, img.height);

          ctx.save();
          if (mode === 'prior') {
            ctx.globalAlpha = 0.5;
          }
          ctx.drawImage(tempCanvas, 0, 0);
          ctx.restore();
        }
      };

      for (const key in damage) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.canvas.width = SIZE;
          ctx.canvas.height = SIZE;
          await drawToCanvas({
            dataUri: damage[key],
            mode: 'main',
            ctx,
          });
          await drawToCanvas({
            dataUri: priorDamage[key],
            mode: 'prior',
            ctx,
          });
        }
        unifiedDamage[key] = canvas.toDataURL();
      }

      canvasData.current = unifiedDamage;
      setRestoreAt(Date.now());
    }

    if (value && overlayValue) {
      injectOverlay({ value, overlayValue });
    }
  }, [value, overlayValue]);

  const onPointerUp = () => {
    setIsPointerDown(false);
    if (canvasData.current.dirty) {
      delete canvasData.current.dirty;
      setStack(stack.concat([JSON.stringify(canvasData.current)]));
    }
  };

  const onUndo = () => {
    const last = stack[stack.length - 2];
    canvasData.current = JSON.parse(last);
    setStack(stack.slice(0, stack.length - 1));
    setRestoreAt(Date.now());
  };

  useEffect(() => {
    onChange(stack.length ? stack[stack.length - 1] : null);
  }, [stack]);

  useEffect(() => {
    if (!isSupported) {
      window.analytics.track(
        'VehicleDamagePicker failed due to unsupported device',
      );
      onChange(JSON.stringify({ unsupported: true }));
    }
  }, [isSupported]);

  const activeButtonClass = isTesla
    ? 'z-10 text-true-black bg-white border-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue uppercase'
    : document.body.classList.contains('tenant-mercury')
    ? 'z-10 text-gray-100 bg-red-800 border-red-800 hover:text-white focus:outline-none focus:border-red-500 focus:shadow-outline-red'
    : 'z-10 text-gray-100 bg-blue-600 border-blue-600 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue';
  const notActiveButtonClass = isTesla
    ? 'text-white bg-black hover:text-gray-100 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-100 uppercase'
    : 'text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800';

  if (!isSupported) {
    return (
      <div className="bg-red-500 p-4 rounded-md text-white font-medium">
        Unfortunately, your browser does not support the technology required to
        render the 3D Damage Picker.
      </div>
    );
  }

  if (viewOnly && initialCanvasData.unsupported) {
    return (
      <div>
        The user's browser did not support the technology required to utilize
        the 3D Damage Picker.
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'rounded relative overflow-hidden select-none',
        !value && 'border border-cool-gray-300',
      )}
      style={{ height: 280 }}
    >
      <Canvas
        style={{ height: 280, touchAction: 'none' }}
        onCreated={({ gl }) => {
          gl.setClearColor(isTesla && !isSubtle ? 0x000000 : 0xffffff);
        }}
        camera={{ position: [-5.5, 2.75, 2.75] }}
        onPointerDown={() => setIsPointerDown(true)}
        onPointerUp={onPointerUp}
        onTouchEnd={onPointerUp}
      >
        {mode === 'move' && <CameraControls />}
        <spotLight position={[0, 0, 10]} intensity={0.75} />
        <spotLight position={[10, 0, 0]} intensity={0.75} />
        <spotLight position={[0, 0, -10]} intensity={0.5} />
        <spotLight position={[-10, 0, 0]} intensity={0.75} />
        <spotLight position={[0, 10, 0]} intensity={0.3} />
        <spotLight position={[0, -10, 0]} intensity={0.2} />
        <spotLight position={[10, 10, 10]} intensity={0.2} />
        <spotLight position={[-10, -10, -10]} intensity={0.2} />
        <Car
          position={[0, -0.5, 0]}
          enableDrawing={mode === 'paint' && isPointerDown}
          // onPointerDown={() => setAllowControls(false)}
          canvasData={canvasData}
          restoreAt={restoreAt}
          isTesla={isTesla}
        />
      </Canvas>
      {!viewOnly && stack.length > 1 ? (
        <div className="absolute bottom-2 left-2">
          <span className="inline-flex rounded-md shadow-sm">
            <button
              onClick={onUndo}
              type="button"
              className={classNames(
                'inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded focus:outline-none focus:border-blue-300 focus:shadow-outline-blue  active:bg-gray-50 transition ease-in-out duration-150',
                isTesla
                  ? 'bg-black text-white uppercase'
                  : 'bg-white text-gray-700 hover:text-gray-500 active:text-gray-800',
              )}
            >
              Undo
            </button>
          </span>
        </div>
      ) : null}
      {!viewOnly && (
        <div className="absolute bottom-2 right-2">
          <span className="inline-flex rounded-md shadow-sm">
            <button
              onClick={() => setMode('move')}
              type="button"
              className={classNames(
                'inline-flex active:z-10 items-center px-2.5 py-1.5 rounded-r-none border border-gray-300 text-xs leading-4 font-medium rounded transition ease-in-out duration-150',
                mode === 'move' ? activeButtonClass : notActiveButtonClass,
              )}
            >
              <svg className="w-3 h-3 mr-1" viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  d="M352.201 425.775l-79.196 79.196c-9.373 9.373-24.568 9.373-33.941 0l-79.196-79.196c-15.119-15.119-4.411-40.971 16.971-40.97h51.162L228 284H127.196v51.162c0 21.382-25.851 32.09-40.971 16.971L7.029 272.937c-9.373-9.373-9.373-24.569 0-33.941L86.225 159.8c15.119-15.119 40.971-4.411 40.971 16.971V228H228V127.196h-51.23c-21.382 0-32.09-25.851-16.971-40.971l79.196-79.196c9.373-9.373 24.568-9.373 33.941 0l79.196 79.196c15.119 15.119 4.411 40.971-16.971 40.971h-51.162V228h100.804v-51.162c0-21.382 25.851-32.09 40.97-16.971l79.196 79.196c9.373 9.373 9.373 24.569 0 33.941L425.773 352.2c-15.119 15.119-40.971 4.411-40.97-16.971V284H284v100.804h51.23c21.382 0 32.09 25.851 16.971 40.971z"
                ></path>
              </svg>
              Move
            </button>
            <button
              onClick={() => setMode('paint')}
              type="button"
              className={classNames(
                'inline-flex active:z-10 items-center px-2.5 py-1.5 rounded-l-none border border-gray-300 text-xs leading-4 font-medium rounded transition ease-in-out duration-150',
                mode === 'paint' ? activeButtonClass : notActiveButtonClass,
              )}
            >
              <svg className="w-3 h-3 mr-1" viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  d="M167.02 309.34c-40.12 2.58-76.53 17.86-97.19 72.3-2.35 6.21-8 9.98-14.59 9.98-11.11 0-45.46-27.67-55.25-34.35C0 439.62 37.93 512 128 512c75.86 0 128-43.77 128-120.19 0-3.11-.65-6.08-.97-9.13l-88.01-73.34zM457.89 0c-15.16 0-29.37 6.71-40.21 16.45C213.27 199.05 192 203.34 192 257.09c0 13.7 3.25 26.76 8.73 38.7l63.82 53.18c7.21 1.8 14.64 3.03 22.39 3.03 62.11 0 98.11-45.47 211.16-256.46 7.38-14.35 13.9-29.85 13.9-45.99C512 20.64 486 0 457.89 0z"
                ></path>
              </svg>
              Paint
            </button>
          </span>
        </div>
      )}
    </div>
  );
};

export default DamagePicker;
