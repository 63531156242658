import classNames from 'classnames';
import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { gql, useMutation, useQuery } from '@apollo/client';
import PageLoader from '@frontend/components/PageLoader';

import config from '../../config';
import Header from '../shared/Header';
import Layout from '../shared/Layout';
import shouldShowVehicleTab from '../utils';
import AuthenticationForm from './components/Authentication/AuthenticationForm';
import { INTAKE_FRAGMENT, IntakeData } from './components/PageLayout/ActionPanel/queries';

const Root = React.lazy(() => import('./components/Root'));

const GET_CLAIM = gql`
  query GetClaim($id: ID!) {
    claim(id: $id) {
      id
      raw
    }
    intakeData: claim(id: $id) {
      ...IntakeFragment
    }
  }

  ${INTAKE_FRAGMENT}
`;

const UPDATE_CLAIM = gql`
  mutation AdminUpdateClaim($id: ID!, $payload: AdminClaimUpdatePayload!) {
    adminUpdateClaim(id: $id, payload: $payload) {
      id
      raw
    }
  }
`;

export default function ClaimView() {
  const { id, view } = useParams<{ id: string; view: string }>();
  const { loading, error, data } = useQuery(GET_CLAIM, {
    variables: { id },
  });
  const [updateClaim_] = useMutation(UPDATE_CLAIM, {});
  const updateClaim = (data: any) => {
    updateClaim_({ variables: { id, payload: { data } } });
  };

  // Delighted customer satisfaction survey
  useEffect(() => {
    if ('delighted' in window && config.enableNpsSurvey) {
      (window as any).delighted.survey({
        properties: {
          tenant: config.tenant,
          claimId: id,
        },
      });
    }
  }, []);

  // Authentication Demo
  const [demoAuthSuccess, setDemoAuthSuccess] = useState(
    !config.demoAuthentication,
  );
  if (!demoAuthSuccess) {
    return (
      <>
        <Header />
        <AuthenticationForm
          onSuccess={() => {
            setDemoAuthSuccess(true);
          }}
        />
      </>
    );
  }

  let enabledTabs;
  const pdf = view === 'pdf';
  const whitelabel = config.whitelabel;

  if (!loading && data) {
    if (config.enabledTabs && data.claim) {
      enabledTabs = config.enabledTabs.slice(0);
      enabledTabs = enabledTabs.filter(t => {
        if (t === 'vehicles') {
          return shouldShowVehicleTab(data.claim.raw);
        }
        return t;
      });
      if (!data.claim.raw.claimVehicle) {
        enabledTabs = enabledTabs.filter(tab => tab !== 'vehicles');
      }
      if (!data.claim.raw.localeDataId) {
        enabledTabs = enabledTabs.filter(tab => tab !== 'locale');
      }
    }
  }

  const inner = (
    <Suspense
      fallback={
        <PageLoader
          style={{ minHeight: '80rvh' }}
          text="Loading ClaimView experience..."
        />
      }
    >
      {loading ? (
        <PageLoader
          style={{ minHeight: '80rvh' }}
          text="Fetching claim information..."
        />
      ) : (
        <div
          className={
            pdf
              ? 'p-6'
              : classNames(
                  'mx-auto sm:px-6 lg:px-8',
                  config.usePlatformMode ? 'py-2' : 'max-w-7xl py-6',
                )
          }
        >
          <div className="px-4 py-6 sm:px-0">
            {(() => {
              if (!id) {
                return (
                  <div className="text-center font-bold text-gray-500">
                    {config.singleClaim
                      ? `Error: Invalid token provided. Please ensure you have clicked a valid link.`
                      : `Please enter a claim ID above to continue.`}
                  </div>
                );
              }

              if (error || !data?.claim) {
                return (
                  <div className="text-center font-bold text-gray-500">
                    {error ? `Failed to load: ${error}` : 'Claim not found'}
                  </div>
                );
              }

              return (
                <Root
                  id={id}
                  intakeData={data.intakeData}
                  claim={data.claim?.raw}
                  user={data.claim?.raw?.user}
                  pdf={pdf}
                  whitelabel={whitelabel}
                  god={config.god}
                  enabledTabs={enabledTabs}
                  useDemoIq={config.useDemoIq}
                  usePlatformMode={config.usePlatformMode}
                  updateClaim={updateClaim}
                />
              );
            })()}
          </div>
        </div>
      )}
    </Suspense>
  );

  if (config.usePlatformMode) {
    return (
      <Layout
        navigation={[
          { href: '/claims', name: 'Intake' },
          {
            current: true,
            name: `${
              data?.claim?.raw.confirmationNumber ||
              data?.claim?.raw.draftNumber ||
              'Loading...'
            }`,
          },
        ]}
      >
        {inner}
      </Layout>
    );
  }

  return (
    <>
      <Header />
      <main style={{ minHeight: '80vh' }}>{inner}</main>
    </>
  );
}
